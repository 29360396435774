import "./css/Information.css";

import { useState, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

import { Link } from "react-router-dom";

import ImageSlider from './components/ImageSlider';
import MapComponent from "./components/MapComponent";


function Information() {

  return (
    <div className="Information">
      <div className="page-content">
        <h1 className="Header">Sporthalle und Anfahrt</h1>
        <p className="InfoText">Lerne beim Radball-Training in der Sporthalle der Gottlieb-Daimler-Oberschule eine neue Sportart und neue Leute kennen. Jede Woche Montags und Donnerstags findest du uns ab 17:00 Uhr vor Ort. Beim Training geht es beim Ludwigsfelder Radball Club mit Leidenschaft und Fairplay zu, damit das ganze Team Spaß am Spiel hat.</p>
        {/* <div className="slider-container">
          <ImageSlider images={["img/Halle/HalleVonAussen1.png", "img/Halle/HalleVonAussen2.png"]}/>
        </div> */}
        <MapComponent pos={[52.302914, 13.245004]} popup={"August-Bebel-Straße 1A, 14974 Ludwigsfelde"} link={"https://maps.app.goo.gl/zMzoxp6mduKUarQf8"}></MapComponent>
        <h1 className="Header">Downloads</h1>
        <div className="DownloadItems">
          <ul>
            <li>
              <a href="/downloads/Satzung des LRC.docx" download>
              <FontAwesomeIcon icon={faFilePdf} /> Satzung des LRC</a>
            </li>
            <li>
              <a href="/downloads/Geschäftsordnung der MV.pdf" download>
              <FontAwesomeIcon icon={faFilePdf} /> Geschäftsordnung der MV</a>
            </li>
            <li>
              <a href="/downloads/Geschäftsordnung des Vorstands.pdf" download>
              <FontAwesomeIcon icon={faFilePdf} /> Geschäftsordnung des Vorstands</a>
            </li>
            <li>
              <a href="/downloads/Ehrenordnung.pdf" download>
              <FontAwesomeIcon icon={faFilePdf} /> Ehrenordnung</a>
            </li>
            <li>
              <a href="/downloads/Beitrags- und Gebührenordnung.pdf" download>
              <FontAwesomeIcon icon={faFilePdf} /> Beitrags- und Gebührenordnung</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Information;
