import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './css/MapComponent.css';

// Custom Icon
const customIcon = new L.Icon({
  iconUrl: 'https://leafletjs.com/examples/custom-icons/leaf-green.png',
  iconSize: [38, 95],
  iconAnchor: [22, 94],
  popupAnchor: [-3, -76],
  shadowUrl: 'https://leafletjs.com/examples/custom-icons/leaf-shadow.png',
  shadowSize: [50, 64],
  shadowAnchor: [4, 62]
});

// Standard-Icon von Leaflet verwenden
const defaultIcon = new L.Icon({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
  shadowSize: [41, 41]
});

const MapComponent = ({pos, popup, link}) => {
  return (
    <MapContainer center={pos} zoom={17} style={{ height: "100vh", width: "100%" }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={pos} icon={defaultIcon}>
        <Popup>
          <p>{popup}</p>
          <p>
            <a href={link} target="_blank" rel="noopener noreferrer">
              Google Maps
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" fill="none" className='icon_externalLink'>
                <rect x="1" y="1" width="14" height="14" stroke="currentColor" stroke-width="1" fill="none" rx="1" ry="1"/>
                <rect x="7" y="0" width="9" height="9" fill="white"/>
                <path d="M10 1l5 0-0 5" stroke="currentColor" stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8 8l7 -7" stroke="currentColor" stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>



              {/* &#x2197; Unicode for an arrow pointing top-right */}
            </a>
          </p>
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default MapComponent;
