import { useState, useEffect} from "react";
import { IconContext } from "react-icons";

import './css/StartIMGSlider.css';

const StartIMGSlider = ({slides}) => {
    const [sliderIDX, setSliderIDX] = useState(0);

    const prevSlide = () => {
        setSliderIDX(sliderIDX === 0 ? slides.length - 1 : sliderIDX - 1)
    };
    const nextSlide = () => {
        setSliderIDX(sliderIDX === 0 ? slides.length - 1 : sliderIDX - 1)
    };

    useEffect(() => {
        const slideInterval = setInterval(() => {
        nextSlide();
        }, 5000); // Wechselt alle 5 Sekunden

        return () => {
        clearInterval(slideInterval);
        };
    }, [sliderIDX]);

  return (
    <IconContext.Provider value={{ className: "shared-class", size: 32 }}>
        <div className="startIMGSlider">
            <div className="imgHolder">
                <img src={slides[sliderIDX].url} alt="startIMG"/>
            </div>
            <h1>{slides[sliderIDX].text}</h1>
        </div>
    </IconContext.Provider>
  )
}

export default StartIMGSlider