import { useState, useEffect} from "react";
import "./css/Schedule.css";

import { Link } from "react-router-dom";
import EventTable from "./components/EventTable";


function Schedule() {

  return (
    <div className="page-background">
      <div className="page-content">
        <h1 className="Header">Termine</h1>
        <div className="tableHolder page-content-element-extraLarge">
          <EventTable maxRows={1000} hidePast={false}></EventTable>
        </div>
      </div>
    </div>
  );
}

export default Schedule;
