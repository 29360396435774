import React from "react";
import "./css/Teams.css";

function Teams() {
  const teams = [
    {
      url: "img/teams/David Tino edit.jpg",
      titel: "1. Mannschaft",
      altersklasse: "Elite",
      liga: "Oberliga",
      torwart: "Tino Erlich",
      feldspieler: "David Friedrich",
    },
    {
      url: "img/teams/Matthias Felix edit.jpg",
      titel: "2. Mannschaft",
      altersklasse: "Elite",
      liga: "Oberliga",
      torwart: "Felix Stege",
      feldspieler: "Matthias Dunkel",
    },
    {
      url: "img/teams/Marco Adrian.jpg",
      titel: "3. Mannschaft",
      altersklasse: "Elite",
      liga: "Oberliga",
      torwart: "Adrian Cranz",
      feldspieler: "Marco Schröder",
    },
    {
      url: "img/teams/Robin Jakob edit.jpg",
      titel: "4. Mannschaft",
      altersklasse: "Elite",
      liga: "Oberliga",
      torwart: "Jakob Igel",
      feldspieler: "Robin Meinhard",
    },
    {
      url: "img/teams/Dummy.png",
      titel: "5. Mannschaft",
      altersklasse: "Elite",
      liga: "Verbandsliga",
      torwart: "Elia Kühnapfel",
      feldspieler: "Tom Meinhard",
    },
    {
      url: "img/teams/U15_Mark_Sean_Feb2020.jpg",
      titel: "6. Mannschaft",
      altersklasse: "Elite",
      liga: "Verbandsliga",
      torwart: "Sean Meine",
      feldspieler: "Mark Shadow",
    },
    {
      url: "img/teams/U17_Jeremy_Ben_Apr2023.jpg",
      titel: "1. Mannschaft",
      altersklasse: "U19",
      liga: "",
      torwart: "Ben Liebetrau",
      feldspieler: "Jeremy Bunzler",
    },
    {
      url: "img/teams/U15_Julius_Laurens_Mar2023.jpg",
      titel: "1. Mannschaft",
      altersklasse: "U17",
      liga: "",
      torwart: "Laurens Parlow",
      feldspieler: "Julius Eckard",
    },
    {
      url: "img/teams/U13_Ole_Carl.jpg",
      titel: "1. Mannschaft",
      altersklasse: "U15",
      liga: "Oberliga",
      torwart: "Ole Mertens",
      feldspieler: "Carl Dittmeyer",
    },
    {
      url: "img/teams/U13_Ben_Mikolaj.jpg",
      titel: "2. Mannschaft",
      altersklasse: "U15",
      liga: "Oberliga",
      torwart: "Ben Zimmermann",
      feldspieler: "Mikolaj Gorski",
    },
    {
      url: "img/teams/Dummy.png",
      titel: "3. Mannschaft",
      altersklasse: "U15",
      liga: "Verbandsliga",
      torwart: "Ansgar Hillebrandt",
      feldspieler: "Noah Müller",
    },
  ];

  return (
    <div className="page-background">
      <div className="page-content">
        <div className="teams-container">
          {teams.map((team, index) => (
            <div className="team-box" key={index}>
              <img src={team.url} alt={`${team.titel}`} className="team-image" />
              <div className="team-info">
                <h3 className="team-title">{team.altersklasse} {team.liga}: {team.titel}</h3>
                <p className="team-details">Torwart: {team.torwart}</p>
                <p className="team-details">Feldspieler: {team.feldspieler}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Teams;
