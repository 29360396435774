import React from 'react';
import './css/History.css';

const History = () => {
  return (
    <div className="page-background">
      <div className="page-content">
        <h1 className="history-title">Geschichte</h1>
        <p className="history-subtitle">Von 1949 bis Heute</p>
        <div className="history-content">
          <section className="history-section page-content-element-large">
            <h2 className="history-section-title">Geburt des Radsports in Ludwigsfelde</h2>
            <p className="history-text">
              Zu Beginn des 20. Jahrhunderts kam das Radballspiel ausschließlich für Männer auf. Man benutzte normale Fahrräder mit  Straßenübersetzung, und spielte im Freien und benutzte einen Ball mit etwa 15 Zentimeter Durchmesser, der fast zwei Pfund wog. In den 20er Jahren wurden die Radballregeln vollkommen verfasst.
            </p>
            <p className="history-text">
              Jetzt benutzt man eine 1:1- Übersetzung - wie schon beim Kunstradfahren üblich. Zu den Spielen ging man dann fast ausschließlich in eine Sporthalle, meist jedoch in den Saal des jeweiligen Dorfgasthofes - so etwa auch 1948 in Ludwigsfelde.
            </p>
          </section>
          <section className="history-section page-content-element-large">
            <h2 className="history-section-title">Die ersten Spieler</h2>
            <p className="history-text">
              Im Ort gab es eine starke Rennfahrertruppe unter dem Friseur Krause mit dem Spitzenfahrer Walter Schwanke, der schon vor dem Krieg bei der Deutschlandrundfahrt teilnahm. Einigen aber waren diese Rennen einfach zu anstrengend. So auch Helmut Siewert, der nach einem Dorfrennen mit Start und Ziel bei Karl Schwanke im Gasthaus Waldfrieden in Struveshof und einem Landesausscheid auf dem von Schuster Achim Borowitzki geborgten Rennrad in Fürstenwalde genug von der Raserei hatte.
            </p>
            <p className="history-text">             
              So wechselte er zu den Saalfahrern, welchen 1948 der Ahrensdorfer Gastwirt Lehmann vier Reigenräder der Marke Brennabor spendete. Zuerst holte man sich im Lehmannschen Saal und in der Turnhalle an der Zwiebelschule (Heute Gebrüder-Grimm-Grundschule Ludwigsfelde) Sicherheit beim Reigen-und Corsofahren. Da es im Gegensatz zu den Reigenfahrern und den Radpolo spielenden Mädchen keine Radballbetreuer gab, mussten sich Helmut Siewer und sein Partner Fritz Heppes selbstständig zum Radballer entwickeln. Kurze Zeit später stieß Joseph Misch - Verantwortlicher der Mädchen - dazu. Die anfallenden Reparaturen erledigten Paul Peter, die Radwerkstatt Zutz und später der unermüdliche Schrauber Willy Föhlich. Zwischenzeitlich trainierte man auch in der Turnhalle der Fliegerschule in Birkengrund-Süd, ehe man in den alten Speisesaal der inzwischen gesprengten Halle 13, die Waldsporthalle zog.
            </p>
          </section>
          <section className="history-section page-content-element-large">
            <h2 className="history-section-title">Entwicklung des Vereins</h2>
            <p className="history-text">
              Die Ludwigsfelder stellten Kontakte zu einem Lichterfelder Radballverein her, tauschten mit den dortigen Radballern Erfahrungen aus, lernten und erhielten von ihnen auch zwei richtige Radballräder. Im Gegensatz zu den „Schreckschussbestien“ genannten Brennaborrädern hatten die neuen Räder Holzfelgen, die bei Mohaupt in Trebbin hergestellt wurden. Später konnte man sich auch speziell gefertigte Rahmen der Eichwalder Firma „Elsner“ leisten. Gespielt wurde meist in den Dorfgasthöfen wie in Großbeeren oder Groß Machnow. Trotz der 1:1- Übersetzung wurde auch der Weg vom Bahnhof Potsdam bis nach Caputh schon mal per Rad zurückgelegt. Die Vereine in Werder und Caputh erinnert sich Helmut Siewert, waren da schon besser organisiert, mit Trainer und Betreuern für die aktiven Sportler. Dennoch gelang Trainer Heinz Seller auf Anhieb ein großer Erfolg mit der ersten Mannschaft. Siewert und Heppes qualifizierten sich sofort für die Aufstiegsspiele zur DDR-Liga, scheiterten dort allerdings und spielten danach in der Bezirksliga. Über Vorwärts, Traktor und Aufbau Ludwigsfelde wurde die BSG Motor Ludwigsfelde die Heimatstadt für die Radballer. Neue Spieler fanden sich ein, wie auch Udo Zahn, der Großbeerener Spiesecke, die Brüder Jürgen und Wolfgang Kerst und Frank Arnold, der erstmals als 12-Jähriger im Oktober 1957 auf dem Saalrad saß .Im Wald hinter der Rathenau-Straße hatten sie sich zunächst auf Tourenrädern im Radball versucht .Später übernahm Frank Arnold mit erst 24 Jahren, für mehrere Jahre die Leitung des Ludwigsfelder Radballclubs.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default History;
