import "./App.css";

import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import React, { useEffect } from "react";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Navbar from "./pages/components/Navbar";
import Footer from "./pages/components/Footer";
import Information from "./pages/Information";
import Schedule from "./pages/Schedule";
import Impressum from "./pages/Impressung";
import History from "./pages/History";
import Teams from "./pages/Teams";

function App() {
  // var data = getProducts();

  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/information" element={<Information />} />
          <Route path="/termine" element={<Schedule />} />
          <Route path="/teams" element={<Teams />} />
          <Route path="/geschichte" element={<History />} />
          <Route path="/impressum" element={<Impressum />} />

          <Route path="/kontakt" element={<Home />} />
          {/* old links */}
          <Route path="/unsere-mannschaften" element={<Teams />} />
          <Route path="/nachwuchs" element={<Teams />} />
          <Route path="/elite" element={<Teams />} />
          <Route path="/informationen" element={<Information />} />
          <Route path="/sporthalle-und-anfahrt" element={<Information />} />
          
          {/* Redirect all unknown links to home */}
          <Route path="/*" element={<Navigate to="/" replace />} />
        </Routes>
        <Footer addressCity={"Ludwigsfelde"} addressPostcode={14974} addressStreet={"Taubenstraße"} addressNumber={"9"}/>
      </BrowserRouter>
    </div>
  );
}

export default App;
