import { useState, useEffect} from "react";
import "./css/Home.css";
import EventTable from "./components/EventTable";
import StartIMGSlider from "./components/StartIMGSlider";

import { Link } from "react-router-dom";


function Home() {
  var slides = []
  if (window.innerWidth < 1024) { // mobile version
    slides = [
      {
        url: "img/start_slider/mobile_1.jpg",
        text: "Willkommen beim Ludwigsfelder Radballclub e.V."
      },
      {
        url: "img/start_slider/mobile_2.jpg",
        text: "Willkommen beim Ludwigsfelder Radballclub e.V."
      },
      {
        url: "img/start_slider/mobile_3.jpg",
        text: "Willkommen beim Ludwigsfelder Radballclub e.V."
      },
      {
        url: "img/start_slider/mobile_4.jpg",
        text: "Willkommen beim Ludwigsfelder Radballclub e.V."
      }
    ]
  } else {  // desktop version
    slides = [
      {
        url: "img/start_slider/desktop_1.jpg",
        text: "Willkommen beim Ludwigsfelder Radballclub e.V."
      },
      {
        url: "img/start_slider/desktop_2.jpg",
        text: "Willkommen beim Ludwigsfelder Radballclub e.V."
      },
      {
        url: "img/start_slider/desktop_3.jpg",
        text: "Willkommen beim Ludwigsfelder Radballclub e.V."
      },
    ]
  }

  return (
    <div className="page-background">
      <StartIMGSlider slides={slides} />
      <div className="page-content">
        <div className="home-schedule">
          <h1>Kommende Heimspieltage</h1>
          <div className="tableHolder page-content-element-large">
            <EventTable maxRows={5} location={"Ludwigsfelde"}></EventTable>
            <p>Weitere Events findest Du hier: <Link to="/termine">Termine</Link></p>
          </div>
        </div>
        <div className="VideoHolder page-content-element-large">
          <iframe src="https://player.vimeo.com/video/432439267?h=f2350597a9&color=ffffff&title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="radballKunstradHolder page-content-element-medium">
          <h1>Erfahre mehr über Radball:</h1>
          <div className="imgHolder">
            <a href="https://www.radball-kunstrad.com/#home" target="_blank">
              <img src={"img/radball_kunstrad/radball-kunstrad-com.png"} alt="Radball Kunstrad IMG"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
