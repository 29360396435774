export const eventTable = [
  {
    "datum": "07.09.2024",
    "altersklasse": "alle Vereine",
    "veranstaltung": "Termintagung",
    "ort": "Ludwigsfelde",
    "date": "2024-09-07T02:00:00.000Z"
  },
  {
    "datum": "14.09.2024",
    "altersklasse": "Elite",
    "veranstaltung": "15. LRC-Cup",
    "ort": "Ludwigsfelde",
    "date": "2024-09-14T02:00:00.000Z"
  },
  {
    "datum": "21.09.2024",
    "altersklasse": "U23",
    "veranstaltung": "U23-Europameisterschaft",
    "ort": "Großkoschen",
    "date": "2024-09-21T02:00:00.000Z"
  },
  {
    "datum": "21./22.09.2024",
    "altersklasse": "Nachwuchs",
    "veranstaltung": "Einladungsturnier",
    "ort": "Luckau",
    "date": "2024-09-22T01:00:00.000Z"
  },
  {
    "datum": "28.09.2024",
    "altersklasse": "Elite",
    "veranstaltung": "Vorrunde Landespokal",
    "ort": "Mühlenbeck",
    "date": "2024-09-28T02:00:00.000Z"
  },
  {
    "datum": "05.10.2024",
    "altersklasse": "U17/U19",
    "veranstaltung": "1. Spieltag",
    "ort": "Großkoschen",
    "date": "2024-10-05T01:00:00.000Z"
  },
  {
    "datum": "12.10.2024 12 Uhr!!!",
    "altersklasse": "Elite",
    "veranstaltung": "Finale Landespokal",
    "ort": "Ludwigsfelde",
    "date": "2024-10-12T01:00:00.000Z"
  },
  {
    "datum": "09.11.2024",
    "altersklasse": "Elite",
    "veranstaltung": "Oberliga 1. Spieltag Gr. 1-2",
    "ort": "Großkoschen",
    "date": "2024-11-09T01:00:00.000Z"
  },
  {
    "datum": "09.11.2024",
    "altersklasse": "Elite",
    "veranstaltung": "Oberliga 1. Spieltag Gr. 3-4",
    "ort": "Großkoschen",
    "date": "2024-11-09T01:00:00.000Z"
  },
  {
    "datum": "09.11.2024",
    "altersklasse": "Elite",
    "veranstaltung": "Einladungsturnier",
    "ort": "Luckau",
    "date": "2024-11-09T01:00:00.000Z"
  },
  {
    "datum": "16.11.2024",
    "altersklasse": "U17/U19",
    "veranstaltung": "2. Spieltag",
    "ort": "Luckau",
    "date": "2024-11-16T01:00:00.000Z"
  },
  {
    "datum": "23.11.2024",
    "altersklasse": "U13/U15",
    "veranstaltung": "1. Spieltag",
    "ort": "Großkoschen",
    "date": "2024-11-23T01:00:00.000Z"
  },
  {
    "datum": "30.11.2024",
    "altersklasse": "Elite",
    "veranstaltung": "Verbandsliga 1. Spieltag Gr. 1-2",
    "ort": "Göhlsdorf",
    "date": "2024-11-30T01:00:00.000Z"
  },
  {
    "datum": "30.11.2024",
    "altersklasse": "Elite",
    "veranstaltung": "Verbandsliga 1. Spieltag Gr. 3-4",
    "ort": "Cottbus",
    "date": "2024-11-30T01:00:00.000Z"
  },
  {
    "datum": "07.12.2024",
    "altersklasse": "Elite",
    "veranstaltung": "Oberliga 2. Spieltag Gr. 1-3",
    "ort": "Ludwigsfelde",
    "date": "2024-12-07T01:00:00.000Z"
  },
  {
    "datum": "07.12.2024",
    "altersklasse": "Elite",
    "veranstaltung": "Oberliga 2. Spieltag Gr. 2-4",
    "ort": "Cottbus",
    "date": "2024-12-07T01:00:00.000Z"
  },
  {
    "datum": "14.12.2024",
    "altersklasse": "LL Nord",
    "veranstaltung": "1. Spieltag",
    "ort": "Neuruppin",
    "date": "2024-12-14T01:00:00.000Z"
  },
  {
    "datum": "14.12.2024",
    "altersklasse": "LL Süd",
    "veranstaltung": "1. Spieltag",
    "ort": "Luckau",
    "date": "2024-12-14T01:00:00.000Z"
  },
  {
    "datum": "14.12.2024",
    "altersklasse": "U13/U15",
    "veranstaltung": "2. Spieltag",
    "ort": "Ludwigsfelde",
    "date": "2024-12-14T01:00:00.000Z"
  },
  {
    "datum": "14.12.2024 13.00 Uhr",
    "altersklasse": "U17/U19",
    "veranstaltung": "3. Spieltag",
    "ort": "Ludwigsfelde",
    "date": "2024-12-14T01:00:00.000Z"
  },
  {
    "datum": "15.12.2024",
    "altersklasse": "Elite",
    "veranstaltung": "Vorrunde DP",
    "date": "2024-12-15T01:00:00.000Z"
  },
  {
    "datum": "01.01.2025",
    "altersklasse": "U 11",
    "veranstaltung": "Meldeschluß",
    "date": "2025-01-01T01:00:00.000Z"
  },
  {
    "datum": "04.01.2025",
    "altersklasse": "Elite",
    "veranstaltung": "Neujahrsturnier",
    "ort": "Cottbus",
    "date": "2025-01-04T01:00:00.000Z"
  },
  {
    "datum": "04.01.2025",
    "altersklasse": "Elite",
    "veranstaltung": "Neujahrsturnier",
    "ort": "Luckenwalde",
    "date": "2025-01-04T01:00:00.000Z"
  },
  {
    "datum": "11.01.2025",
    "altersklasse": "LL Nord",
    "veranstaltung": "2. Spieltag",
    "ort": "Göhlsdorf",
    "date": "2025-01-11T01:00:00.000Z"
  },
  {
    "datum": "11.01.2025",
    "altersklasse": "LL Süd",
    "veranstaltung": "2. Spieltag",
    "ort": "Spremberg",
    "date": "2025-01-11T01:00:00.000Z"
  },
  {
    "datum": "11.01.2025",
    "altersklasse": "U13/U15",
    "veranstaltung": "3. Spieltag",
    "ort": "Lüblow",
    "date": "2025-01-11T01:00:00.000Z"
  },
  {
    "datum": "18.01.2025",
    "altersklasse": "Elite",
    "veranstaltung": "Oberliga 3. Spieltag Gr. 1-4",
    "ort": "Großkoschen",
    "date": "2025-01-18T01:00:00.000Z"
  },
  {
    "datum": "18.01.2025",
    "altersklasse": "Elite",
    "veranstaltung": "Oberliga 3. Spieltag Gr. 2-3",
    "ort": "Cottbus",
    "date": "2025-01-18T01:00:00.000Z"
  },
  {
    "datum": "25.01.2025",
    "altersklasse": "Elite",
    "veranstaltung": "Verbandsliga 2. Spieltag Gr. 1-3",
    "ort": "Göhlsdorf",
    "date": "2025-01-25T01:00:00.000Z"
  },
  {
    "datum": "25.01.2025",
    "altersklasse": "Elite",
    "veranstaltung": "Verbandsliga 2. Spieltag Gr. 2-4",
    "ort": "Berlin",
    "date": "2025-01-25T01:00:00.000Z"
  },
  {
    "datum": "25.01.2025",
    "altersklasse": "U17/U19",
    "veranstaltung": "4. Spieltag",
    "ort": "Neetzow",
    "date": "2025-01-25T01:00:00.000Z"
  },
  {
    "datum": "02.02.2025",
    "altersklasse": "Elite",
    "veranstaltung": "Viertelfinale DP",
    "date": "2025-02-02T01:00:00.000Z"
  },
  {
    "datum": "15.02.2025",
    "altersklasse": "alle Vereine",
    "veranstaltung": "Jahreshauptversammlung BRV",
    "ort": "M.-Buchholz",
    "date": "2025-02-15T01:00:00.000Z"
  },
  {
    "datum": "15.02.2025",
    "altersklasse": "U17/U19",
    "veranstaltung": "Landesmeisterschaften",
    "ort": "Cottbus",
    "date": "2025-02-15T01:00:00.000Z"
  },
  {
    "datum": "22.02.2025",
    "altersklasse": "Elite",
    "veranstaltung": "Verbandsliga 3. Spieltag Gr. 1-4",
    "ort": "Mühlenbeck",
    "date": "2025-02-22T01:00:00.000Z"
  },
  {
    "datum": "22.02.2025",
    "altersklasse": "Elite",
    "veranstaltung": "Verbandsliga 3. Spieltag Gr. 2-3",
    "ort": "Luckenwalde",
    "date": "2025-02-22T01:00:00.000Z"
  },
  {
    "datum": "01.03.2025",
    "altersklasse": "Elite",
    "veranstaltung": "Oberliga 4. Spieltag Gr. 1-2",
    "ort": "Berlin",
    "date": "2025-03-01T02:00:00.000Z"
  },
  {
    "datum": "01.03.2025 14.00 Uhr",
    "altersklasse": "Elite",
    "veranstaltung": "Oberliga 4. Spieltag Gr. 3-4",
    "ort": "Mühlenbeck",
    "date": "2025-03-01T02:00:00.000Z"
  },
  {
    "datum": "08.03.2025",
    "altersklasse": "U 11",
    "veranstaltung": "1. Spieltag",
    "ort": "Cottbus",
    "date": "2025-03-08T02:00:00.000Z"
  },
  {
    "datum": "08.03.2025",
    "altersklasse": "LL Nord",
    "veranstaltung": "3. Spieltag",
    "ort": "Luckenwalde",
    "date": "2025-03-08T02:00:00.000Z"
  },
  {
    "datum": "08.03.2025",
    "altersklasse": "LL Süd",
    "veranstaltung": "3. Spieltag",
    "ort": "Cottbus",
    "date": "2025-03-08T02:00:00.000Z"
  },
  {
    "datum": "08.03.2025",
    "altersklasse": "U13/U15 OL",
    "veranstaltung": "4. Spieltag",
    "ort": "Luckenwalde",
    "date": "2025-03-08T02:00:00.000Z"
  },
  {
    "datum": "08.03.2025",
    "altersklasse": "U13/U15 VL",
    "veranstaltung": "4. Spieltag",
    "ort": "Luckau",
    "date": "2025-03-08T02:00:00.000Z"
  },
  {
    "datum": "15.03.2025",
    "altersklasse": "Elite",
    "veranstaltung": "Verbandsliga 4. Spieltag Gr. 1-2",
    "ort": "Neuruppin",
    "date": "2025-03-15T02:00:00.000Z"
  },
  {
    "datum": "15.03.2025",
    "altersklasse": "Elite",
    "veranstaltung": "Verbandsliga 4. Spieltag Gr. 3-4",
    "ort": "Großkoschen",
    "date": "2025-03-15T02:00:00.000Z"
  },
  {
    "datum": "16.03.2025",
    "altersklasse": "U17/U19",
    "veranstaltung": "Viertelfinale DM",
    "date": "2025-03-16T01:00:00.000Z"
  },
  {
    "datum": "22.03.2025",
    "altersklasse": "Elite",
    "veranstaltung": "Halbfinale DP",
    "date": "2025-03-22T02:00:00.000Z"
  },
  {
    "datum": "21.-23.03.2025",
    "altersklasse": "Nachwuchs",
    "veranstaltung": "D-Kader",
    "ort": "?",
    "date": "2025-03-23T01:00:00.000Z"
  },
  {
    "datum": "29.03.2025",
    "altersklasse": "U13/U15",
    "veranstaltung": "Landesmeisterschaften",
    "ort": "Ludwigsfelde",
    "date": "2025-03-29T02:00:00.000Z"
  },
  {
    "datum": "30.03.2025 11 Uhr",
    "altersklasse": "U23",
    "veranstaltung": "Landesmeisterschaften",
    "ort": "Großkoschen",
    "date": "2025-03-30T01:00:00.000Z"
  },
  {
    "datum": "05.04.2025",
    "altersklasse": "Elite",
    "veranstaltung": "Oberliga 5. Spieltag Gr. 1-3",
    "ort": "Ludwigsfelde",
    "date": "2025-04-05T02:00:00.000Z"
  },
  {
    "datum": "05.04.2025",
    "altersklasse": "Elite",
    "veranstaltung": "Oberliga 5. Spieltag Gr. 2-4",
    "ort": "Großkoschen",
    "date": "2025-04-05T02:00:00.000Z"
  },
  {
    "datum": "05.04.2025",
    "altersklasse": "LL Nord",
    "veranstaltung": "4. Spieltag",
    "ort": "Neuruppin",
    "date": "2025-04-05T02:00:00.000Z"
  },
  {
    "datum": "05.04.2025",
    "altersklasse": "LL Süd",
    "veranstaltung": "4. Spieltag",
    "ort": "Großkoschen",
    "date": "2025-04-05T02:00:00.000Z"
  },
  {
    "datum": "05.04.2025",
    "altersklasse": "U 11",
    "veranstaltung": "2. Spieltag",
    "date": "2025-04-05T02:00:00.000Z"
  },
  {
    "datum": "12.04.2025",
    "altersklasse": "U17/U19",
    "veranstaltung": "Halbfinale DM",
    "date": "2025-04-12T02:00:00.000Z"
  },
  {
    "datum": "27.04.2025",
    "altersklasse": "U13/U15",
    "veranstaltung": "Viertelfinale DM",
    "date": "2025-04-27T01:00:00.000Z"
  },
  {
    "datum": "03.05.2025",
    "altersklasse": "Elite",
    "veranstaltung": "Verbandsliga 5. Spieltag Gr. 1-3",
    "ort": "Großkoschen",
    "date": "2025-05-03T02:00:00.000Z"
  },
  {
    "datum": "03.05.2025",
    "altersklasse": "Elite",
    "veranstaltung": "Verbandsliga 5. Spieltag Gr. 2-4",
    "ort": "Mühlenbeck",
    "date": "2025-05-03T02:00:00.000Z"
  },
  {
    "datum": "03.05.2025",
    "altersklasse": "Elite",
    "veranstaltung": "Finale DP",
    "date": "2025-05-03T02:00:00.000Z"
  },
  {
    "datum": "10.05.2025 14.00 Uhr",
    "altersklasse": "Elite",
    "veranstaltung": "Oberliga 6. Spieltag Gr. 1-4",
    "ort": "Ludwigsfelde",
    "date": "2025-05-10T02:00:00.000Z"
  },
  {
    "datum": "10.05.2025 14.00 Uhr",
    "altersklasse": "Elite",
    "veranstaltung": "Oberliga 6. Spieltag Gr. 2-3",
    "ort": "Ludwigsfelde",
    "date": "2025-05-10T02:00:00.000Z"
  },
  {
    "datum": "17.05.2025",
    "altersklasse": "U 11",
    "veranstaltung": "3. Spieltag",
    "date": "2025-05-17T02:00:00.000Z"
  },
  {
    "datum": "17./18.05.2025",
    "altersklasse": "U17/U19",
    "veranstaltung": "Deutsche Meisterschaft",
    "date": "2025-05-18T01:00:00.000Z"
  },
  {
    "datum": "24.05.2025",
    "altersklasse": "U23",
    "veranstaltung": "Viertelfinale U23-DP",
    "date": "2025-05-24T02:00:00.000Z"
  },
  {
    "datum": "24.05.2025",
    "altersklasse": "U13/U15",
    "veranstaltung": "Halbfinale DM",
    "date": "2025-05-24T02:00:00.000Z"
  },
  {
    "datum": "31.05.2025",
    "altersklasse": "Elite/U19",
    "veranstaltung": "Vorrunde Aufst. z. 2. BL",
    "date": "2025-05-31T02:00:00.000Z"
  },
  {
    "datum": "14.06.2025 14 Uhr",
    "altersklasse": "Elite",
    "veranstaltung": "Verbandsliga 6. Spieltag Gr. 1-4",
    "ort": "Ludwigsfelde",
    "date": "2025-06-14T02:00:00.000Z"
  },
  {
    "datum": "14.06.2025 14 Uhr",
    "altersklasse": "Elite",
    "veranstaltung": "Verbandsliga 6. Spieltag Gr. 2-3",
    "ort": "Ludwigsfelde",
    "date": "2025-06-14T02:00:00.000Z"
  },
  {
    "datum": "14./15.06.2025",
    "altersklasse": "U13/U15",
    "veranstaltung": "Deutsche Meisterschaft",
    "ort": "Wiesbaden",
    "date": "2025-06-15T01:00:00.000Z"
  },
  {
    "datum": "21.06.2025",
    "altersklasse": "U 11",
    "veranstaltung": "4. Spieltag",
    "date": "2025-06-21T02:00:00.000Z"
  },
  {
    "datum": "21.06.2025",
    "altersklasse": "Elite/U19",
    "veranstaltung": "Aufstiegsspiele zur VL",
    "date": "2025-06-21T02:00:00.000Z"
  },
  {
    "datum": "22.06.2025",
    "altersklasse": "U23",
    "veranstaltung": "Halbfinale U23-DP",
    "date": "2025-06-22T01:00:00.000Z"
  },
  {
    "datum": "28.06.2025",
    "altersklasse": "Elite/U19",
    "veranstaltung": "Finale Aufst. z. 2. BL",
    "date": "2025-06-28T02:00:00.000Z"
  },
  {
    "datum": "05.07.2025",
    "altersklasse": "U23",
    "veranstaltung": "U23-Deutschlandpokal-Finale",
    "date": "2025-07-05T02:00:00.000Z"
  },
  {
    "datum": "12.07.2025",
    "altersklasse": "U 11",
    "veranstaltung": "Meisterschaft",
    "date": "2025-07-12T02:00:00.000Z"
  },
  {
    "datum": "06.09.2025",
    "altersklasse": "alle Vereine",
    "veranstaltung": "Termintagung",
    "ort": "Ludwigsfelde",
    "date": "2025-09-06T02:00:00.000Z"
  }
];