import "./css/Navbar.css";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { IconContext } from "react-icons";
import { BiMessageDetail } from "react-icons/bi";
import { RxHamburgerMenu } from "react-icons/rx";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="selectNavbar">
      {/* Desktop Menü */}
      <div className="FlexRow desktopMenu">
        <div className="mainLogo">
          <Link to="/">
            <img src="img/logo/logo.svg" alt="mainLogo" />
          </Link>
        </div>
        <div className="FlexRow NavbarLinks">
          <Link to="/" className="NavbarLink">Start</Link>
          <Link to="/termine" className="NavbarLink">Termine</Link>
          <Link to="/teams" className="NavbarLink">Teams</Link>
          <Link to="/information" className="NavbarLink">Informationen</Link>
          <Link to="/geschichte" className="NavbarLink">Geschichte</Link>
          <Link to="/impressum" className="NavbarLink">Impressum</Link>
        </div>
      </div>

      {/* Mobile Menü */}
      <div className="FlexRow mobileMenu">
        <div className="mainLogo">
          <Link to="/">
            <img src="img/logo/logo.svg" className="mainLogoIMG" alt="mainLogo" />
          </Link>
        </div>
        
        <IconContext.Provider value={{ className: "shared-class", size: 32 }}>
          <RxHamburgerMenu className="icon navbarExtend" onClick={toggleMenu} />
        </IconContext.Provider>

        {/* Overlay Menü für mobile Ansicht */}
        <div className={`mobileNavOverlay ${isMenuOpen ? 'open' : ''}`}>
          <div className="overlayContent">
            <Link to="/" className="NavbarLink" onClick={toggleMenu}>Start</Link>
            <Link to="/termine" className="NavbarLink" onClick={toggleMenu}>Termine</Link>
            <Link to="/teams" className="NavbarLink" onClick={toggleMenu}>Teams</Link>
            <Link to="/information" className="NavbarLink" onClick={toggleMenu}>Information</Link>
            <Link to="/geschichte" className="NavbarLink" onClick={toggleMenu}>Geschichte</Link>
            <Link to="/impressum" className="NavbarLink" onClick={toggleMenu}>Impressum</Link>
          </div>
        </div>

        {/* Halbtransparenter Hintergrund bei offenem Menü */}
        {isMenuOpen && <div className="backdrop" onClick={toggleMenu}></div>}
      </div>
    </div>
  );
};

export default Navbar;
