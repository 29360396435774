import "./css/Footer.css";

import { Link } from "react-router-dom";
import React from "react";

import { GiCupcake } from "react-icons/gi";
// import { BiMessageDetail } from "react-icons/bi";
import { IconContext } from "react-icons";

const Footer = ({addressStreet, addressNumber, addressPostcode, addressCity}) => {
  

  return (
    <div className="chooseFooter">
      <div className="desktopFooter">
        <div className="desktopFooterFlex">
          <div className="FooterCol">
            <div className="FooterRow">
              <div className="footerLogo">
                <Link to="/">
                  <div className="imgholder">
                    <img
                      src="img/logo/logo.svg"
                      className="footerLogoIMG"
                      alt="footerLogo"
                    ></img>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="FooterCol">
            <div className="FooterAddress">
              <h3>Ludwigsfelder Radballclub e.V.</h3>
              <p>{addressStreet} {addressNumber}</p>
              <p>{addressPostcode} {addressCity}</p>
            </div>
          </div>
          <div className="FooterCol">
            {/* <Link to="/" className="FooterLink">Start</Link>
            <Link to="/termine" className="FooterLink">Termine</Link>
            <Link to="/information" className="FooterLink">Information</Link>
            <Link to="/impressum" className="FooterLink">Impressum</Link> */}
          </div>
        </div>
      </div>
      <div className="mobileFooter">
        <div className="desktopFooterFlex">
          <div className="FooterCol">
            <div className="FooterRow">
              <div className="footerLogo">
                <Link to="/">
                  <div className="imgholder">
                    <img
                      src="img/logo/logo.svg"
                      className="footerLogoIMG"
                      alt="footerLogo"
                    ></img>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="FooterCol">
            <div className="FooterAddress">
              <h3>Ludwigsfelder Radballclub e.V.</h3>
              <p>{addressStreet} {addressNumber}</p>
              <p>{addressPostcode} {addressCity}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
