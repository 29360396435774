import "./css/EventTable.css";
// src/components/EventTable.jsx
import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { eventTable } from '../../data/data_turniere.jsx';

const EventTable = ({maxRows = 10, location = null, hidePast = true}) => {
  // Funktion zur Überprüfung, ob das Event in der Vergangenheit liegt
  const isPastEvent = (event) => {
    const eventDate = new Date(event.date);
    const currentDate = new Date();
    return eventDate < currentDate;
  };
  
  // Funktion zum Filtern und Begrenzen der Einträge
  const getFilteredEvents = (eventTable, maxRows, location = null) => {
    // Filtere Events, die in der Vergangenheit liegen
    var upcomingEvents = eventTable.filter(event => !isPastEvent(event));
    // Überprüfe den Ort und filtere die Events
    if (location !== null)
      upcomingEvents = upcomingEvents.filter(event => event.ort === location);

    // Beschränke die Anzahl der angezeigten Events auf maxRows
    return upcomingEvents.slice(0, maxRows);
  };

  const [combine4mobile, setCombine4mobile] = useState(window.innerWidth < 1023); // Beispiel für mobile Ansicht
  const filteredEvents = getFilteredEvents(eventTable, maxRows, location);
  var PastEvents = []
  if (!hidePast)
    PastEvents = eventTable.filter(event => isPastEvent(event))
  
  useEffect(() => {
    const handleResize = () => {
      setCombine4mobile(window.innerWidth < 1023); // Setze combine4mobile je nach Fensterbreite
    };

    window.addEventListener('resize', handleResize);
    
    // Cleanup Funktion: Entfernen des Event Listeners bei Unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='EventTable'>
      <table>
        <thead>
        {combine4mobile ? (
            <tr>
              <th>Datum</th>
              <th>Veranstaltung</th>
            </tr>
          ) : (
          <tr>
            <th>Datum</th>
            <th>Altersklasse</th>
            <th>Veranstaltung</th>
            {location === null && <th>Ort</th>}
          </tr>
          )}
        </thead>
        <tbody>
          {filteredEvents.map((col, index) => (
            <tr key={index}>
              {/* Nur die ersten zwei Spalten anzeigen, wenn combine4mobile true ist */}
              {combine4mobile ? (
                <>
                  <td className="EventTable_datum">{col.datum}</td>
                  <td className="EventTable_veranstaltung">
                    <p>{col.altersklasse}</p>
                    <p>{col.veranstaltung}</p>
                    {location === null && <p>{col.ort}</p>}
                  </td>
                </>
              ) : (
                <>
                  <td className="EventTable_datum">{col.datum}</td>
                  {/* <td className="EventTable_uhrzeit">{col.start + " - " + col.end}</td> */}
                  <td className="EventTable_altersklasse">{col.altersklasse}</td>
                  <td className="EventTable_veranstaltung">{col.veranstaltung}</td>
                  {location === null && <td className="EventTable_ort">{col.ort}</td>}
                </>
              )}
            </tr>
          ))}
          {PastEvents.map((col, index) => (
            <tr key={index}>
              {/* Nur die ersten zwei Spalten anzeigen, wenn combine4mobile true ist */}
              {combine4mobile ? (
                <>
                  <td className="EventTable_datum eventTable-pastEvent">{col.datum}</td>
                  <td className="EventTable_veranstaltung eventTable-pastEvent">
                    <p>{col.altersklasse}</p>
                    <p>{col.veranstaltung}</p>
                    {location === null && <p>{col.ort}</p>}
                  </td>
                </>
              ) : (
                <>
                  <td className="EventTable_datum eventTable-pastEvent">{col.datum}</td>
                  {/* <td className="EventTable_uhrzeit">{col.start + " - " + col.end}</td> */}
                  <td className="EventTable_altersklasse eventTable-pastEvent">{col.altersklasse}</td>
                  <td className="EventTable_veranstaltung eventTable-pastEvent">{col.veranstaltung}</td>
                  {location === null && <td className="EventTable_ort eventTable-pastEvent">{col.ort}</td>}
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EventTable;
